import { get, post } from "./api-client";
import type { SubscribeViewModel } from "@/types/models/subscribe/subscribe-view-model";
import type { ContactUsViewModel, OrderNowViewModel, ApplicatorMessageViewModel } from "@/types/models/email/contact-us-view-model";

export default {
    subscribeNewsletter: async (model: SubscribeViewModel) => post('/mail/subscribeNewsletter', model),

    verifyRecaptcha: async (response: string) => post<{ success: Boolean, challenge_ts: Date, hostname: String, "error-codes"?: [] }>('/googleRecaptcha/verify', { response }),

    contactUsSend: async (data: ContactUsViewModel) => post('/mail/contactUsSend', data),

    freeQuateSend: async (data: OrderNowViewModel) => post('/mail/freeQuateSend', data),

    sendApplicatorMessage: async (data: ApplicatorMessageViewModel) => post('/mail/sendApplicatorMessage', data)
};